import $ from 'jquery'
import slick from 'slick-carousel'
import LazyLoad from "vanilla-lazyload"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
import ModalVideo from 'modal-video'
import 'jquery-modal'

/* SETUP SLICK
  ----------------------------- */
export const setupSlideshow = (el, type = 'basic', slidesNumber = 1) => {
  const $el = $(el);
  const opts = {
    basic: {
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      centerMode: false,
      rtl: false,
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '24px',
        }
      }]
    },
    rtl: {
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      centerMode: false,
      rtl: true,
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '24px',
        }
      }]
    }
  }

  $el.slick(opts[type])
}

/* LAZY LOAD
  ----------------------------- */
export const lazyLoad = () => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    data_src: "src",
  })
}

/* SMOOTH SCROLL
  ----------------------------- */
export const smoothScroll = () => {
  $('a[href*="#"]:not([href="#"]):not([href="#top"]):not([target="_blank"]):not([href*="#modal"])').click(function(e) {
    const target = $(this.hash)
    if (target.length > 1) e.preventDefault()
    $('html, body').animate({ scrollTop: target.offset().top-100 }, 1000)
  })

  $("a[href='#top']").click(function(e) {
    e.preventDefault()
    $('html, body').animate({ scrollTop: 0 }, 800)
  })
}

/* HEADER ANIMATION
  ----------------------------- */
export const menuOverlay = () => {
  const toggle = $('.header__toggle')
  const bar = $('.header__toggle .bar')
  const overlay = $('.header--offcanvas, .header__inner, .content-area, body')
  const link = $('.header--offcanvas a');
  const header = $('.header--main');

  toggle.on('click', function () {
    overlay.toggleClass('open')
    bar.toggleClass('animate')
    toggle.toggleClass('open')
  })

  link.on('click', function () {
    overlay.removeClass('open')
    bar.removeClass('animate')
    toggle.removeClass('open')
  })

  // window.onresize = () => {
  //   overlay.removeClass('open')
  //   bar.removeClass('animate')
  //   toggle.removeClass('open')
  // }

  $(document).scrollTop() > 200 ?
    header.addClass('sticky') :
    header.removeClass('sticky')

  $(window).scroll(function(e) {
    $(document).scrollTop() > 200 ?
      header.addClass('sticky') :
      header.removeClass('sticky')

    this.oldScroll > this.scrollY ?
      header.addClass('sticky-show').removeClass('sticky-hide') :
      header.addClass('sticky-hide').removeClass('sticky-show')
    this.oldScroll = this.scrollY
  })
}

/* SETUP MODALVIDEO
  ----------------------------- */
export const setupModalVideo = () => {
  if ($('.js-modal-btn').length > 0 || $('.js-modal-btn-vimeo').length > 0) {
    new ModalVideo('.js-modal-btn')
    new ModalVideo('.js-modal-btn-vimeo', { channel: 'vimeo' })
  }
}

/* SETUP ACCORDION
  ----------------------------- */

export const setupAccordion = () => {
  $('.toggle-accordion').on('click', function() {
    if ($(this).children('.accordion').hasClass('hide')) {
      $('.toggle-accordion .accordion').addClass('hide');
      $(this).children('.accordion').removeClass('hide');
    } else { 
      $(this).children('.accordion').addClass('hide');
    }
  })
}

/* SETUP CATEGORY QUIZ CLASS
  ----------------------------- */

export const setupCategoryQuizClass = () => {
  $('.quiz-cat').each(function() {
    var cat_name = $(this).html();

    $(this).addClass('quiz-cat-' + cat_name.toLowerCase());
  })
}


/* ADD DESCRIPTION NEAR TIMER
----------------------------- */

export const addDescriptionNearTimer = () => {
  var timer = $('.custom-mlw_qmn_timer');
  var description_it = "<p class='medium'>Hai <b>2 minuti</b> di tempo.<br>Scegli una sola risposta.</p>";
  var description_en = "<p class='medium'>You have <b>2 minutes</b> to answer.<br>Choose only one answer.</p>";

  var html_lang = $('html').attr('lang');

  timer.wrap('<div class="header__timer flex items-center pt15"><div class="timer__wrapper"></div></div>');
  var timer_wrapper = $('.timer__wrapper');
  if (html_lang == 'it-IT') {
    timer_wrapper.after(description_it);
  } else {
    timer_wrapper.after(description_en);
  }
}

/* ADD TOTAL QUESTIONS NUMBER
----------------------------- */

export const addTotalQuestionsNumber = () => {
  var total_questions = $('#total_questions').val(); 
  if(total_questions != undefined) {
    $('.mlw_qmn_question_number').each(function() {
      var question_number = $(this);
      var question_number_text = question_number.text().replace('.', '');
      question_number.text(question_number_text + '/' + total_questions);
    });

    $('.qsm-pagination .qsm-submit-btn').on('click', function() {
      setTimeout(function() {
        $('.qmn_results_page .mlw_qmn_question_number').each(function() {
          var question_number = $(this);
          var question_number_text = question_number.text().replace('.', '');
          question_number.text(question_number_text + '/ ' + total_questions);
        });
      }, 2000);
    });
  }

  var anwsers = document.querySelectorAll('.qmn_question_answer');
  var total_anwsers = anwsers.length;
  var qsm_results_page = document.querySelector('.qsm-results-page');
  var question_number_i = 1;

  if (qsm_results_page) {
    $('.qsm-results-page .mlw_qmn_question_number').each(function() {
      var question_number = $(this);
      question_number.text(question_number_i + '/ ' + total_anwsers);
      question_number_i++;
    });
  }
}

/* RANKING TABLE MODIFICATION
----------------------------- */

export const setupRanking = () => {
  var table = $('.quiz_leaderboard_wrapper');

  if (table.length > 0) {
    table.find('tbody tr').each(function() {
      // move timer at end line
      var cells = $(this).find('td');
      if (cells.length > 4) {
        var fourthCell = cells.eq(3);
        var fifthCell = cells.eq(4);
        var thirdCell = cells.eq(2); 
        
        fifthCell.html(fourthCell.html() + '/' + fifthCell.html());
        fourthCell.remove();
        $(this).append(thirdCell);
      }

      // timer
      var timeCell = $(this).find('td').eq(3);
      var text = timeCell.text().replace(/\s+/g, '');
      console.log(text);

      if (text.includes('hr')) {
          var parts = text.split('hr');
          text = parts[1] ? parts[1].trim() : '';
      }
      
      text = text.replace('sec', '').replace('min', ':').replace('::', ':').trim();
      var minutes = '0', seconds = '00';

      if (text.includes(':')) {
          var timeParts = text.split(':');
          if (timeParts.length === 2) {
              minutes = timeParts[0].trim() || '0';
              seconds = timeParts[1].trim() || '00';
          } else if (timeParts.length === 3) {
              minutes = timeParts[1].trim() || '0';
              seconds = timeParts[2].trim() || '00';
          }
      } else {
          seconds = text.trim() || '00';
      }

      seconds = seconds.padStart(2, '0');
      text = minutes + ':' + seconds;
      timeCell.text(text);
      console.log(text);

      // points
      var pointsCell = $(this).find('td').eq(4);
      var points = pointsCell.text();
      pointsCell.text('/' + points);

      // position
      var current_user = $('.section__ranking .current_user').val();
      var position = $(this).find('td').eq(1);

      if (position.text() == current_user) {
        position.addClass('current_user');
      }
  });
  }
}

/* SETUP RANKING SHORT
----------------------------- */

export const setupRankingShort = () => {
  const rows = document.querySelectorAll('.ranking-filtered .quiz_leaderboard_wrapper tr');
    
  let currentIndex = -1;
  rows.forEach((row, index) => {
      if (row.querySelector('.current_user')) {
          currentIndex = index;
      }
  });

  const totalRows = rows.length;

  if (currentIndex !== -1 && currentIndex !== 1 && currentIndex !== 2 && currentIndex !== totalRows - 2 && currentIndex !== totalRows - 1) {
      let start = Math.max(1, currentIndex - 2);
      let end = Math.min(rows.length - 1, currentIndex + 2);

      rows.forEach((row, index) => {
          if (index >= start && index <= end) {
              row.style.display = '';
          } else if (index !== 0) {
              row.style.display = 'none';
          }
      });
  } else if (currentIndex !== -1 && currentIndex == 1) {
    let start = Math.max(1, currentIndex - 4);
    let end = Math.min(rows.length - 1, currentIndex + 4);

    rows.forEach((row, index) => {
        if (index >= start && index <= end) {
            row.style.display = '';
        } else if (index !== 0) {
            row.style.display = 'none';
        }
    });
  } else if (currentIndex !== -1 && currentIndex == 2) {
    let start = Math.max(1, currentIndex - 3);
    let end = Math.min(rows.length - 1, currentIndex + 3);

    rows.forEach((row, index) => {
        if (index >= start && index <= end) {
            row.style.display = '';
        } else if (index !== 0) {
            row.style.display = 'none';
        }
    });
  } else if (currentIndex === totalRows - 2) {
    let start = Math.max(1, currentIndex - 3);
    let end = Math.min(rows.length - 1, currentIndex + 3);

    rows.forEach((row, index) => {
        if (index >= start && index <= end) {
            row.style.display = '';
        } else if (index !== 0) {
            row.style.display = 'none';
        }
    });
  } else if (currentIndex === totalRows - 1) {
    let start = Math.max(1, currentIndex - 4);
    let end = Math.min(rows.length - 1, currentIndex + 4);

    rows.forEach((row, index) => {
        if (index >= start && index <= end) {
            row.style.display = '';
        } else if (index !== 0) {
            row.style.display = 'none';
        }
    });
  } else {
    rows.forEach((row, index) => {
      if (index > 5) {
          row.style.display = 'none';
      } else {
          row.style.display = '';
      }
    });
  }
  
}

/* TIMER ANIMATION
  ----------------------------- */

  export const timerAnimation = () => {
    //timer pop up
    var message_timeout = $('.qsm-popup .qsm-popup__container .qsm-popup__content .qsm-time-up-text');
    var button_timeout = $('.qsm-popup .qsm-popup__container .qsm-popup__footer .qmn_btn.pagetime-goto-nextpage');

    message_timeout.text('Tempo scaduto!');
    button_timeout.text('Continua');


    //timer
    var timer_wrapper = $('.timer__wrapper');
    var radius = 24;
    var circumference = 2 * Math.PI * radius;
    var cx = 30;
    var cy = 34;

    timer_wrapper.append('<div id="countdown"></div>');
    timer_wrapper.append('<svg id="countdown-svg"><circle r="' + radius + '" cx="'+ cx +'" cy="'+ cy +'" style="stroke-dasharray: ' + circumference + '; stroke-dashoffset: ' + circumference + ';"></circle></svg>');
    var circle = $('.timer__wrapper circle');

    var timer = $('.custom-mlw_qmn_timer');
    var raw_time = timer.text();
    var total_seconds = 120;

    var t = setInterval(getTimer, 1000);

    function startTimer() {
      t = setInterval(getTimer, 1000);
    }

    function stopTimer() {
        clearInterval(t);
    }

    $('.current_page_hidden').on('change', function() {
        stopTimer();
        setTimeout(function() {
            startTimer();
        }, 1000);
    });

    startTimer();

    function formatTimerText(raw_time) {
      var timeParts = raw_time.split(':');
      if (timeParts[0] === '00') {
          return timeParts[1] + ':' + timeParts[2];
      } else {
          return raw_time;
      }
    }
    
    function getTimer() {
        timer = $('.custom-mlw_qmn_timer');
        raw_time = timer.text();
        
        var formattedTime = formatTimerText(raw_time);

        var countdown = $('#countdown');
        countdown.text(formattedTime);
        
        var timeParts = raw_time.split(':');
        var seconds = parseInt(timeParts[0]) * 3600 + parseInt(timeParts[1]) * 60 + parseInt(timeParts[2]) - 1;
    
        var maxTime = total_seconds;
        var offset = (1 - (seconds / maxTime)) * circumference;
        if (seconds < 0) {
            offset = 0;
        }
        circle.css('stroke-dashoffset', offset);
    
        if (raw_time === '00:00:00') {
            clearInterval(t);
        }
    }
  }