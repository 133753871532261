import $ from 'jquery'
import * as project from './functions'
import './polyfill'

window.onload = () => {
  project.lazyLoad()
  project.smoothScroll()
  project.menuOverlay()
  project.setupModalVideo()
  project.setupAccordion()
  project.setupCategoryQuizClass()
  project.addDescriptionNearTimer()
  project.setupRanking()
  project.setupRankingShort()
  project.timerAnimation()
  project.addTotalQuestionsNumber()

  if ($('.slider-init').length > 0) {
    $('.slider-init').each(function() {
      project.setupSlideshow($(this), $(this).data('slick'), $(this).data('slides'))
    })
  }

  detectIncognito().then((result) => {
    const host = window.location.origin + '/';
    let old_url = window.location.href;
    if (result.isPrivate && window.location.href !== host) {
      setTimeout(() => {
        alert('Please disable incognito mode to continue')
      }, 300)
      console.log(result, `window.location.href: ${window.location.href}`, `host: ${host}`)
      window.location.href = '/'
    }
  });

  const lang = 'lang-' + $('html').attr('lang')
  $('body').addClass(lang)

  const bodyClasses = $('body').attr("class").split(/\s+/)
  
  $.each(bodyClasses, function(key, value) {
    switch(value) {
      case "home":
        break

      case "single-qsm_quiz":
        project.quizWithRandomQuestions()
        
        setTimeout(function() {
          var element = document.querySelector('.mlw_custom_start');
          if (element) {
            element.click();
          }
        }, 100);
      default:
        $('#loader').fadeOut(500)
    }
  })
}